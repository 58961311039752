import styles from "./promo-app.scss?inline";
import { html, unsafeCSS, LitElement } from "lit";
import { isAndroid, isIOS } from "mobile-device-detect";
import { customElement, state } from "lit/decorators.js";
import "urlpattern-polyfill";
import { Router } from "@lit-labs/router";
import "../../components/PromoLoader/promo-loader.js";
import "../PromoPlaceholder/promo-placeholder.js";
import { TData } from "../../models/TData";
import { baseUrl } from "../../env";
import { language } from "../../utils/i18n";
import { translate } from "../../utils/i18n";

@customElement("promo-app")
export class PromoApp extends LitElement {
  static styles = unsafeCSS(styles);

  private _router = new Router(this, [
    {
      pattern: new URLPattern({ pathname: "/" }),
      render: () => html`<promo-placeholder></promo-placeholder>`,
    },
    {
      pattern: new URLPattern({ pathname: "/:alias{/}?" }),
      render: () => html`<promo-main .data="${this._data}"></promo-main>`,
      enter: async ({ alias }) => {
        this._alias = alias;
        await import("../PromoMain/promo-main.js");
        return true;
      },
    },
    {
      // pattern: new URLPattern({ hash: "guide-android" }),
      pattern: new URLPattern({ pathname: "/:alias/guide-android{/}?" }),
      render: () => html`<guide-android .data="${this._data}"></guide-android>`,
      enter: async ({ alias }) => {
        if (!isAndroid) {
          window.location.pathname = `/${alias}`;
          return false;
        }
        this._alias = alias;
        await import("../Guides/guide-android.js");
        return true;
      },
    },
    {
      // pattern: new URLPattern({ hash: "guide-ios" }),
      pattern: new URLPattern({ pathname: "/:alias/guide-ios{/}?" }),
      render: () => html`<guide-ios .data="${this._data}"></guide-ios>`,
      enter: async ({ alias }) => {
        if (!isIOS) {
          window.location.pathname = `/${alias}`;
          return false;
        }
        this._alias = alias;
        await import("../Guides/guide-ios.js");
        return true;
      },
    },
  ]);

  @state()
  protected _data: TData = null;

  @state()
  protected _isLoading = true;

  @state()
  protected _alias?: string;

  async connectedCallback() {
    super.connectedCallback();

    document.title = translate("index.title");

    if (this._alias) {
      try {
        const response = await fetch(
          `${baseUrl}/api/mobile/promo/${this._alias}/${language}`
        );
        if (response.status >= 200 && response.status < 300)
          this._data = await response.json();
      } catch (_) {}
    }
    this._isLoading = false;
  }

  render() {
    if (this._isLoading) {
      return html`<promo-loader></promo-loader>`;
    }

    if (!this._data) {
      return html`<promo-placeholder></promo-placeholder>`;
    }

    return this._router.outlet();
  }
}
