import en from "../translations/en.json";
import ru from "../translations/ru.json";
import mn from "../translations/mn.json";
import { supportedLocales, defaultLocale } from "../config";

declare global {
  interface Window {
    EventiciousSDK: any;
  }
}

const localizations = [
  {
    locale: "ru-RU",
    language: "ru",
  },
  {
    locale: "en-US",
    language: "en",
  },
  {
    locale: "mn-MN",
    language: "mn",
  },
];

export const convertLocaleToLanguage = (locale: string) => {
  const result = localizations.find(
    (localization) => localization.locale === locale
  );
  return result?.language;
};

export const convertLanguageToLocale = (language: any) => {
  const result = localizations.find(
    (localization) => localization.language === language
  );
  return result?.locale;
};

const translations: Record<string, Record<string, string>> = {
  "en-US": en,
  "ru-RU": ru,
  "mn-MN": mn,
};

export const getLocale = () => {
  const localeFromURL = new URLSearchParams(window.location.search).get(
    "locale"
  );
  const localeFromSDK = window.EventiciousSDK?.locale();
  const localeFromBrowser = convertLanguageToLocale(
    navigator.language.slice(0, 2)
  );

  const locale = localeFromURL || localeFromSDK || localeFromBrowser;

  return supportedLocales.includes(locale) ? locale : defaultLocale;
};

export const language = convertLocaleToLanguage(getLocale());

if (language) {
  document?.documentElement.setAttribute("lang", language);
}

export const translate = (key: string) => {
  const locale = getLocale();
  return translations[locale][key];
};
