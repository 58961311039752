import { envApiUrls } from "eventicious-env";

let APP_ENV = "test-yandex";

if (process.env.APP_ENV) {
  APP_ENV = process.env.APP_ENV;
}

const getEnv = (env = APP_ENV) => envApiUrls[env];

const baseUrlParam = new URLSearchParams(window.location.search).get("baseUrl");

export const baseUrl = baseUrlParam || getEnv().mobileApiHost;
